@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  scroll-behavior: smooth;
}

body {
  margin: 0;

  min-height: 100vh;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter", sans-serif;
}

.green {
  color: #01be96;
}

.slick-list,
.slick-slider,
.slick-track {
  padding: 1.5rem 0;
}

.center .slick-center .project {
  transform: scale(1.3);
  z-index: 10000;
  transition: all 400ms ease-in-out;
  pointer-events: all;
  opacity: 1;
}

.center .project {
  opacity: 0.7;
  pointer-events: none;
}



/* scroll bar style  */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes header {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

:root {
  background-color: white;
}

scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari and Opera */
}

.react-datepicker-wrapper {
  width: 100%;
}

.slick-slider {
  padding: 0;
}

.slider-container.few-cards .slick-track {
  display: flex !important;
  justify-content: flex-start !important;

}

@media (min-width: 768px) {
  .slick-track {
    height: 18.3rem !important;

  }
}

@media (min-width: 768px) {
  .two-cards .slick-slide.slick-active.slick-current {
    width: 30% !important;
  }
}


@media (min-width: 768px) {
  .three-cards .slick-slide.slick-active {
    width: 340px !important;
  }
}
